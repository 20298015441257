body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,  'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.file-list {
  margin-top: 10px;
}

.file-list h5 {
  margin-bottom: 5px;
  color: #fff; /* Adjust color to match your theme */
}

.file-list ul {
  list-style-type: none;
  padding-left: 0;
}

.file-list li {
  margin-bottom: 5px;
}

.file-list a {
  color: #fff; /* Adjust color to match your theme */
  text-decoration: none;
}

.file-list a:hover {
  text-decoration: underline;
}

.file-list i {
  margin-right: 5px;
}

.profile-image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 200px; /* Adjust as needed */
}

.profile-icon{
  width: 200px;
  height: 200px;
}

