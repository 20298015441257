/* colors.css */
:root {
  --primary-color: #FFFFFF;
  /* --secondary-color: #425d74; */
  --secondary-color: #ed6a5a;
  --secondary-dark-color: #cf6117;
  --text-color:#000;
  --nav-li-color: #000000;
  --section-background: #1c929a;
  --our-team-text: #000000;
  /* --orange-color: #FE7A36; */
  --heading-color: #1c929a;
  --silver-color: #dbdbdb;
  --gray-color: #818181;
  --white2-color: #f9f9f9;
  --sky-color: #e1e4e5;
  /* Add more color constants as needed */
}