.gradient-custom {
  /* fallback for old browsers */
  background: white;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(253, 127, 32, 1), rgba(0,0,0, 1));
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -webkit-linear-gradient(to right, rgba(253, 127, 32, 1), rgba(0,0,0, 1));
  }

  .form-input{
    color: '#000';
    background-color: var(--primary-color);
  }

.form-input::placeholder {
    font-size: 14px;
}


  .form-buttons {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    background-color: var(--heading-color);
    border: none;
    cursor: pointer;
    color: var(--primary-color);
    font-weight: bold;
    transition: transform 0.3s ease-in-out;
}

.form-buttons:hover {
  transform: scale(1.1);
}
