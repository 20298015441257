/* Apply a shadow to a button */
.shadow1 {}

.shadow1:hover {}


.cpt {
  padding-top: 80px;
}

.cpb {
  padding-bottom: 80px;
}

.cpl {
  padding-left: 80px;
}

.cpr {
  padding-right: 80px;
}

.cp {
  padding: 80px;
}


.cmt {
  margin-top: 80px;
}

.cmb {
  margin-bottom: 80px;
}

.cml {
  margin-left: 80px;
}

.cmr {
  margin-right: 80px;
}

.common-margin {
  margin: 80px;
}

.heading {
  font-weight: 600;
}

/* Header css start */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  width: 22px;
  height: 3px;
  background-color: #333;
  margin: 2px 0;
  transition: transform 0.3s ease-in-out;
}


.open .hamburger span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .hamburger span:nth-child(2) {
  opacity: 0;
}

.open .hamburger span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.app_logo {
  display: flex;
  justify-content: flex-start;
}

.application-logo {
  width: auto;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  /* margin: 0 20px; */
}

/* .app_logo a {
  font-size: 30px;
  color: var(--secondary-color);
} */

.header {
  background-color: var(--primary-color);
  display: flex;
  height: 80px;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.header nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.header nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header nav li {
  margin: 0 10px;
  display: flex;
  align-items: center;
  /* Center align vertically */
}

/* .header nav ul li {
  list-style: none;
  margin-right: 10px;
} */

.header nav ul li a {
  text-decoration: none;
  color: var(--nav-li-color);
  font-size: 14px;
  font-weight: 600;
}

.header_contact_btn {
  margin-left: 30px;
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 14px;
  background-color: var(--secondary-color);
  border: none;
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

.header_contact_btn:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.header_login_btn {
  margin-left: 10px;
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 14px;
  background-color: var(--heading-color);
  border: none;
  cursor: pointer;
  color: var(--sky-color);
  text-decoration: none;
  font-weight: bold;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
}

.header_login_btn:hover {
  transform: scale(1.1);
}

.login-container {
  background-color: var(--sky-color);
  padding: 20px 40px;
  border-radius: 10px;
}

.profile_header_image {
  /* padding-left: 20px; */
}

.header_profile_image {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: 'gray';
  object-fit: 'cover'
}


.header_profile_container {
  position: relative;
  margin-left: 20px;
}

.profile_dropdown_container {
  /* height: 100px; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  transition: display 0.3s ease;
  padding-top: 10px;
  max-width: 400px;
}

.dropdown_innerContainer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.profile_dropdown_container li {
  padding-bottom: 5px;
  cursor: pointer;
  margin: 0px !important;
}

.header_profile_section {
  padding-top: 10px;
  flex-direction: column;
  display: 'flex'
}

.header_profile_section h6 {
  font-weight: bold;
  white-space: nowrap;
  line-height: 0.1;
}



.header_profile_container:hover {
  .profile_dropdown_container {
    display: block;
  }
}



.services {
  position: relative;
}

.dropdown-container {
  position: absolute;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  border-radius: 5px;
  max-width: 1024px;
  overflow: hidden;
  /* Initially hide the dropdown */
}

.category-container {
  padding: 15px;
}

.category-container .category-items {
  display: flex;
  flex-direction: column;
  /* Add your custom styles for category-items */
}

.category-items-list {
  align-items: center;
  justify-content: center;
  display: flex;
}

.category-heading {
  white-space: nowrap;
  color: var(--secondary-color)
}

.category-items a {
  color: var(--secondary-color) !important;
  font-size: 12px !important;
  font-weight: normal !important;
  cursor: pointer;
}

.services:hover .dropdown-container {
  display: block;
  /* Show the dropdown when hovering over SERVICES */
}

/* Header css End */


/* Home css */
.home_container {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.home_banner {
  position: relative;
  background-color: var(--primary-color);
  overflow: hidden;
  height: 100%;
  padding-top: 30px;
  /* padding-bottom: 80px; */
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  position: relative;
}

.box {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  background-color: #fff;
  margin: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.box h2 {
  font-weight: 400;
  color: var(--heading-color);
}

.circle {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-image: url('../src/assets/circle.png');
  background-size: cover;
  opacity: 0.1;
  z-index: 0;
  animation: rotateCircle 10s linear infinite;
}

@keyframes rotateCircle {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    /* Initial rotation */
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
    /* Final rotation */
  }
}

.banner-content {
  line-height: 30px;
}

.banner-content h1 {
  /* padding-top: 50px; */
  font-weight: 600;
  color: var(--heading-color);
  /* background: linear-gradient(135deg, #1c929a, #464646); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.banner-content h1 span {
  color: var(--nav-li-color);
}

.banner-content p {
  padding-top: 30px;
  text-align: justify;
}

.banner-contact-btn {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--heading-color);
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: bold;
  transition: transform 0.3s ease-in-out;
}

.banner-contact-btn:hover {
  transform: scale(1.1);
}


@media (max-width:1440px) {
  .box {
    margin: 10px;
  }
}

@media (max-width: 425px) {
  .banner-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Home end */

.text-break {
  /* font-weight: bold; */
  color: var(--gray-color);
  line-height: 1.5;
  font-size: 17px;
}

.card-body {
  padding: 0px;
}

.custom-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-color: var(--silver-color);
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 30px;
  cursor: pointer;
  /* Add transition for smooth effect */
}

/* Apply styles on hover */
.custom-card:hover {
  transform: scale(1.05);
  /* Scale up slightly on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow on hover */
}

.card-image-container {
  overflow: hidden;
  object-fit: contain;
}

.card-image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  vertical-align: top;
  max-height: 200px;
  /* Adjust the max height as needed */
}


.section3 {
  height: 390px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(https://images.unsplash.com/photo-1582389197103-1ac281b8a6c9?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center;
  position: relative;
}

/* .section3:hover{
  transform: scale(1.05);
} */
/* Additional styles for content */
.section3-content {
  position: absolute;
  top: 0;
  left: 10%;
  bottom: 0;
  right: 10%;
  text-align: center;
  color: white;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ;
  transition: transform 0.2s, box-shadow 0.2s;
}


.section3-content:hover {
  transform: scale(1.05);
}

.section3-content h1 {
  /* padding-top: 30px; */
}

.orange_color {
  color: var(--heading-color);
  text-decoration: none;
}

.client-count-box h2 {
  color: var(--heading-color);
}

@media (max-width: 425px) {
  .section3-content h1 {
    white-space: nowrap;
    /* display: inline;  */
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .client-counts .row>div {
    margin-bottom: 0px !important;
  }
}


/* section 5 */
.section4 {
  background-color: var(--sky-color);
}


.slick-button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  background-color: var(--heading-color);
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-button-next {
  right: -15px;
  margin: 0;
  padding: 0;
  z-index: 100;
}

.slick-button-prev {
  left: -15px;
  margin: 0;
  padding: 0;
  z-index: 100;
}

.slick-button-next:hover {
  background-color: var(--secondary-color);
}

.slick-button-prev:hover {
  background-color: var(--secondary-color);
}


.slider-box {
  /* width: 250px; */
  /* background-color:blue; */
  background-color: var(--sky-color);
  border: none;
  /* padding: 20px 20px 20px 20px; */
  /* Add margin between boxes */
  padding: 20px 10px 20px 10px;
  text-align: center;
}

.slider-card {
  height: 120px;
  border: none;
  background-color: var(--primary-color);
  ;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, box-shadow 0.2s;
}

.slider-card:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}


.slider-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.slider-profile-image {
  width: 150px;
  object-fit: contain;
}

.slider-card-body h1 {
  font-size: 30px;
}

.slider-card-body p {
  height: 100%;
  margin-top: 20px;
  line-height: 1.5;
}

.slider-card-body a {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.slider-description {
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  -webkit-line-clamp: 2;
  /* Number of lines before truncating */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  /* Adjust as needed */
}


.section5 {
  background-color: var(--sky-color);
}


.attorney-slider-box {
  background-color: var(--sky-color);
  border: none;
  padding: 20px 10px 20px 10px;
  text-align: center;
}

.attorney-slider-card {
  border: none;
  background-color: var(--primary-color);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, box-shadow 0.2s;
}

.attorney-slider-card:hover {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}

.attorney-slider-card-body {
  padding: 25px 20px;
}

.attorney-slider-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attorney-slider-box-image {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.attorney-content {
  padding-top: 20px;
}

.attorney-content h2 {
  font-size: 18px;
  color: var(--our-team-text);
  font-weight: bold;
}

.attorney-content p {
  font-size: 14px;
  color: var(--our-team-text);
}


/* SocialIcons.css */
.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.icon-color {
  color: var(--secondary-color);
}

.social-icon:hover {
  background-color: var(--sky-color);
  /* Gray background color on hover */
}

.empty-space {
  width: 100%;
  height: 60px;
}

/* YourComponent.css */
.content-container {
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
  padding: 20px;
}

.content-container h2 {
  flex: 65%;
  margin: 0;
  font-size: 26px;
}

.content-container button {
  flex: 1;
  background-color: transparent;
}

.content-container button:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}


/* Footer style */
.footer {
  width: 100%;
  height: auto;
  /* Set your desired custom height */
  background-color: var(--nav-li-color);
  /* Example background color */
  color: white;
  /* Example text color */
  display: flex;
}

.footer-card {
  background-color: var(--nav-li-color);
  border: none;
}

.footer-card-body {
  padding: 0;
}

.footer-card-body p {
  color: white;
}

.horizontal-line {
  border: none;
  border-top: 2px solid var(--secondary-color);
  /* Color of the line */
  margin: 20px 20px 20px 0px;
  /* Adjust the margin as needed */
}

.footer-detail-txt {
  font-size: 14px;
}


/* DetailComponent.css */
.detail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 10px;
}

.detail-item p {
  margin: 0;
  font-size: 14px;
  color: var(--primary-color);
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--secondary-color);
}

.day {
  margin: 0;
}


/* WorkingHours.css */
.working-hours {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.working-hours-item {
  display: flex;
  justify-content: space-between;
  /* Align items with space between */
}

.icon {
  width: 12px;
  height: 12px;
}

/* Footer style end */



/* Paractice start */
/* colors.css is assumed to have the color variables */

.practice-section {
  margin-top: 80px;
  padding-bottom: 30px;
}
.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  border-radius: 10px;
  overflow: hidden; /* Ensures the image and overlay stay within the container */
}

.practice-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.4s ease; /* Add smooth zoom-in effect on hover */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to bottom, 
      rgba(0, 0, 0, 0.1),  /* Darker overlay at the top */
      rgba(0, 0, 0, 0.5)   /* Stronger at the bottom */
  ); /* Smooth gradient overlay */
  display: flex;
  align-items: flex-end; /* Align title at the bottom */
  justify-content: center;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border-radius: 10px;
  opacity: 1; /* Always show the overlay */
  transition: opacity 0.3s ease;
}

.image-container:hover .practice-image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.overlay-title {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Add shadow for better readability */
  letter-spacing: 1px;
}


.service-details {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.service-title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: bold;
}

.paractice-desc {
  font-size: 16px;
  color: var(--text-color);
  line-height: 1.6;
}

.services-card {
  background-color: var(--white2-color);
  border: none;
}

.services-heading {
  color: var(--heading-color);
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.services-list {
  list-style: none;
  padding-left: 0;
}

.service-item {
  padding: 10px 15px;
  background-color: var(--white2-color);
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.service-item:hover {
  background-color: var(--sky-color);
}

.active-service {
  background-color: var(--section-background) !important;
  color: var(--primary-color) !important;
  font-weight: bold !important;
}

.active-icon {
  margin-right: 10px;
  color: var(--primary-color);
}

.brochure-card {
  background-color: var(--sky-color);
  border: none;
  padding: 20px;
}

.brochure-title {
  color: var(--text-color);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.brochure-text {
  color: var(--gray-color);
  margin-bottom: 20px;
  font-size: 16px;
}

.custom-btn {
  background-color: var(--section-background);
  border: none;
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.custom-btn:hover {
  background-color: var(--secondary-dark-color);
}

.talk-card {
  background-color: var(--section-background);
  color: var(--primary-color);
  padding: 20px;
  border: none;
}

.phone-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.talk-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.talk-text {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.custom-btn-outline {
  border-color: var(--primary-color);
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.custom-btn-outline:hover {
  background-color: var(--primary-color);
  color: var(--section-background);
}

.whatsapp-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #25D366; /* WhatsApp green color */
}
/* Service details styling */
.service-details {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Arial', sans-serif; /* Professional font */
}

/* Paragraph styling */
.paractice-desc {
  font-size: 18px;
  color: var(--text-color);
  line-height: 1.8;
  margin-bottom: 20px; /* Add spacing between paragraphs */
  font-weight: 400; /* Standard weight for easy reading */
}

/* List styling */
.paractice-list {
  margin-left: 0px;
  margin-bottom: 20px;
  padding-left: 30px;
  list-style-type: disc;
  font-size: 18px;
}

/* List item styling */
.list-item {
  margin-bottom: 15px;
  font-weight: 500; /* Slightly bold for readability */
  color: var(--gray-color); /* Light gray color for regular list items */
}

/* List title styling */
.list-title {
  font-weight: 700; /* Bold for list titles */
  font-size: 20px; /* Slightly bigger */
  color: var(--heading-color); /* Use your brand's heading color */
  display: block;
  margin-bottom: 5px; /* Space between title and description */
}

/* List description styling */
.list-desc {
  font-weight: 400; /* Regular weight for descriptions */
  color: var(--text-color);
  font-size: 18px;
  line-height: 1.6;
}

/* Responsive improvements */
@media (max-width: 768px) {
  .paractice-desc,
  .paractice-list,
  .list-title,
  .list-desc {
    font-size: 16px;
  }
}



/* parectice end */

/* genral ask questions */
.general-ask {
  margin-top: 40px;
}

.general-ask-heading {
  font-size: 24px;
  font-weight: bold;
  color: var(--heading-color);
}

.accordion-button {
  box-shadow: none !important; /* Removes the blue outline */
  outline: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--secondary-color); /* Active state background color */
  color: white;
}

.accordion-button:focus {
  box-shadow: none !important; /* Prevents the outline on focus */
}

.icon {
  font-size: 16px;
  color: white;
}

.accordion-body {
  /* background-color: var(--silver-color); */
  padding: 20px;
  color: var(--text-color);
}

.accordion-button::after {
  display: none !important; /* Hides Bootstrap's default toggle icon */
}

/* genral ask questions End */

/* about us */

.about-us {
  margin-top: 80px;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url('https://images.unsplash.com/photo-1423592707957-3b212afa6733?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80');
  object-fit: contain;
  background-position: 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-content h2 {
  color: var(--primary-color);
  font-size: 30px;
}

.img-fluid {
  border-radius: 5px;
}

.about-row {
  align-items: flex-start !important;
}

.about-text-para {
  font-size: 16px;
  color: var(--gray-color);
  line-height: 1.5;
  font-weight: 400;
}

.about-text-para-heading {
  font-size: 30px;
}

@media (max-width: 991px) {
  .about_us_container {
    flex-direction: column-reverse;
  }
}

/* services */
.services-section {
  min-height: 600px;
  padding-top: 80px;
}

.our_services {
  /* width: 250px; */
  height: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.our_services img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  padding-bottom: 10px;
}

.our_services h3 {
  color: var(--heading-color);
  font-weight: 400px;
  font-size: 22px;
}

.our_services p {
  line-height: 25px;
  text-justify: inter-word;
  color: var(--gray-color);
  font-size: 14px;
}

.our_services a {
  color: var(--secondary-color) !important;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}


.services_section {
  background-color: var(--sky-color);
  /* padding-top: 80px; */
  /* padding-bottom: 30px; */
}


/* Contact us  */
.contact-us {
  width: 100%;
  object-fit: contain;
  background-position: 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-content h2 {
  color: var(--primary-color);
  font-size: 30px;
}

.form-control {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.btn-custom {
  background-color: var(--heading-color);
  width: 150px;
}

.btn-custom:hover {
  border-width: 1px;
  border-color: var(--heading-color);
}

.read-more {
  color: var(--heading-color);
  margin-top: 20px;
}

/* .read-more:hover {
  background-color: var(--heading-color);
  border-style: none;
}

.read-more:active {
  background-color: var(--heading-color);
} */



.cat_services_container {
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.cat_service_content {
  display: flex;
  /* justify-content: center; */
  padding: 20px;
  align-items: center;
  height: 50px;
  background-color: #f2f4f5;
  cursor: pointer;
  position: relative;
}

.cat_service_content:hover {
  background-color: var(--primary-color);

  .cat_service_line {
    background-color: var(--secondary-color);
  }
}

.active_service {
  background-color: var(--primary-color);
}

.register_button {
  width: 150px;
  height: 45px;
  border: none;
  background: var(--heading-color);
  border-radius: 5px;
}

.cat_service_line {
  position: absolute;
  width: 2px;
  height: 90%;
  left: 0%;
  right: 100px;
  top: 0px;
}

.heading_test .color {
  font-weight: 600;
  color: var(--heading-color);
}


/* service registration */
.service_registration {
  padding-top: 80px;
}


/* profile screen */
.profile_section {
  padding-top: 80px;
  ;
}

.divider {
  width: 100%;
  background-color: var(--secondary-color);
  padding: 1px;
}

.profile_data h3 {
  color: var(--text-color);
}

.profile_data p {
  color: var(--text-color);
}

.profile_description p {
  color: var(--text-color);
  text-align: center;
  padding-top: 50px;
}

.heading_color {
  color: var(--text-color);
}

/* profile screen end */

/* query modal */
.custom-accordion-header{
  padding-bottom: 20px;
}
.custom-accordion-header .accordion-button{
  padding: 10px 20px;
}
.query-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scrollable-content {
  overflow-y: auto;
  flex-grow: 1;
}

.query-input .form-control {
  flex-grow: 1;
}

.query-input .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.query-input {
  display: flex;
  gap: 10px;
}
.query-contact-btn{
  padding: 5px 10px !important;
  margin-right: 20px !important;
}

.scrollable-content {
  padding-right: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
}

/* For Webkit browsers (Chrome, Safari) */
.scrollable-content::-webkit-scrollbar {
  padding: 0px;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-content {
  display: flex;
  flex-direction: column;
}
.message-content p{
  margin-bottom: 5px;
}

.user-message {
  background: #e3f2fd;
  border-left: 4px solid #2196f3;
}

.admin-message {
  background: #fff3e0;
  border-left: 4px solid #ff9800;
}

.file-attachments {
  margin-top: 5px;
}

.file-attachment {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.file-list {
  border-top: 1px solid #ddd;
  max-height: 200px;
}

.file-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-attachment:hover {
  background-color: #f0f0f0;
}

.file-name {
  margin-right: 8px;
  font-weight: 500;
}

.file-icon {
  color: #888888;
}

/* responsive */
@media (max-width: 991px) {
  .app_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 40px 20px 20px;
    align-items: center;
  }

  .header_container {
    flex-direction: column;
  }

  .header nav {
    flex-direction: column;
    display: none;
    margin: auto;
    width: 100%;
    align-items: flex-start;
    padding-left: 50px;
  }

  .header nav ul {
    flex-direction: column;
    padding: 0px;
    align-items: flex-start;
  }

  .application-logo {
    height: 40px;
  }

  .hamburger {
    display: flex;
  }

  .open {
    height: 350px;
    transition: height 0.3s ease;
  }

  .open nav {
    display: flex;
  }

  .header nav li {
    margin-bottom: 20px;
    justify-content: center;
  }

  .header nav ul li a {
    color: var(--nav-li-color);
    margin-right: 0px;
  }


  /* Home screen */

  .box-container {
    justify-content: center !important;
  }

  .home_inner {
    flex-direction: column-reverse;
  }

  .box {
    width: auto;
    height: auto;
    padding: 10px 30px 10px 30px;
  }


  /* home end */

  /* our servics */
  .our_services h3 {
    font-size: 18px;
  }

  /* our services end */

}


/* modal style */
/* .bottom-modal .modal-dialog {
  position: fixed;
  bottom: 50%;
  left: 0;
  right: 0;
  width: 100%;
  transform: none;
  z-index: 99999;
}

.bottom-modal .modal-content {
  border-radius: 0;
  border: none;
  background-color: white;
} */

/* modal end */



@media (max-width: 991px) {
  .content-container h2 {
    font-size: 20px;
  }

  .content-container button {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .content-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .content-container h2 {
    font-size: 18px;
  }

  .content-container button {
    font-size: 12px;
    margin-top: 20px;
  }

  .slick-button-next {
    margin-right: 5px;
    z-index: 1;
  }

  .slick-button-prev {
    margin-left: 5px;
    z-index: 1;
  }

  .practice-image {
    height: 250px;
  }

  .reason_image {
    height: auto !important;
  }

  .footer-container {
    padding: 50px 20px 50px 20px !important;
  }
}

@media (max-width: 768px) {
  .slick-button {
    width: 30px;
    height: 30px;

  }

  .slick-button-next {
    right: -30px;
  }

  .slick-button-prev {
    left: -30px;
  }

}

@media (max-width:425px) {
  .about-us {
    height: 250px;
    margin-top: 10px;
  }
}

@media (max-width:575px) {
  .service_banner {
    flex-direction: column;
  }
}










/* 404 page  */



.page_404 {
  padding: 100px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}